import Home from "./Home";
import Travel from "./Travel";
import Registry from "./Registry";
import Questions from "./Questions";

function Router() {
  const pathname = window.location.pathname;
  switch (pathname) {
    case "/travel":
      return <Travel />;
    case "/registry":
      return <Registry />;
    case "/faq":
      return <Questions />;
    default:
      return <Home />;
  }
}

function App() {
  return <Router />;
}

export default App;