export default {
    breakpoints: ["40em", "64em"],
    fontSizes: {
        small: 13, 
        regular: 16, 
        large: 20,
        xlarge: 32, 
    },
    colors: {
        text_dark: "#333333",
        text_light: "white",
        text_lighter: "white",
        sage: "#2C3F1F",
        peach: "#F8EEE0",
    },
    space: {
        n_hugest: -128,
        n_huge: -64,
        tiny: 2, 
        small: 4,
        medium: 8,
        large: 16, 
        xlarge: 32,
        huge: 64,
    },
    fonts: {
        heading: "Recoleta-Black, system-ui, sans-serif",
        subheading: "PPEiko-LightItalic, system-ui, sans-serif",
        body: "Recoleta-Regular, system-ui, sans-serif",
        subtext: "Recoleta-Thin, system-ui, sans-serif",    
    },
    text: {
        heading: {
            color: "text_light",
            fontFamily: "heading",
            fontSize: ["large", "xlarge"],
            lineHeight: "1.4",
        },
        subheading: {
            color: "text_dark",
            fontFamily: "subheading",
            fontSize: ["small", "regular"],
            lineHeight: "1.4",
        },
        body: {
            color: "text_dark",
            fontFamily: "body",
            fontSize: ["regular", "large"],
            lineHeight: "1.4",
            paddingBottom: "baseTheme.space.large",
        },
        subtext: {
            color: "text_light",
            fontFamily: "subtext",
            fontSize: ["regular", "large"],
            lineHeight: "1.4",
        },
    },
};