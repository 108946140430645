import React, { Component } from "react"
import { baseTheme, Container, Section, Title, WavySection,
    DefaultWrapper, Paragraph, HeaderNav, Subheading, DisplayText, Subtext } from "./components";
import { Flex, Box, } from "rebass"


var faqList = [
    {
        q: "Can I bring a plus one?",
        a: "If your invite is addressed to you and a guest you are welcome to bring \
            someone along to join us for the weekend. If your invite is addressed to only you, we do not have space to extend you a plus one."
    },
    {
        q: "When should I RSVP by?",
        a: "Kindly RSVP by May 1 so that we can have an accurate headcount." 
    },
    {
        q: "Is there parking?",
        a: "If you’d like to bring a car to the wedding venue, please let us know in advance! \
            The venue only accommodates a few cars, \
            so we encourage everyone to arrange for shuttling to and from the venue."
    },
    {
        q: "Are pets allowed?",
        a: "No, unfortunately the venue does not allow pets."
    },
    {
        q: "Anything else?",
        a: "Please text or email us if you have any other questions!"
    },
];


class Questions extends Component {
    renderHeading(headingText) {
        return (
            <DisplayText 
                fontSize={[baseTheme.fontSizes.medium, baseTheme.fontSizes.medium]}
                pb={[baseTheme.space.tiny]}
            >
                &#10045; &nbsp; {headingText}
            </DisplayText>
        )
    }

    renderContent(content) {
        let offset = "24px";
        return (
            <Paragraph ml={[0, offset]}>
                {content}
            </Paragraph>
        )
    }

    render() {
        let offsetSizing = [baseTheme.space.n_tiny, baseTheme.space.n_huge];

        let defaultBottomPadding = [baseTheme.space.small, baseTheme.space.small];
        let defaultTopPadding = [0, baseTheme.space.tiny];

        return (
            <>
            <HeaderNav />
            <Container>
                <WavySection height={["160px", "240px", "400px"]}>
                    <Title my={baseTheme.space.small} className="fadeInUpBig">
                        FAQ
                    </Title>
                </WavySection>                    
                <Section backgroundColor={baseTheme.colors.seafoam} mt={offsetSizing} minHeight={"70vh"}>
                    <DefaultWrapper>
                        {faqList.map((faq, index) => 
                            (
                                <Box pt={defaultTopPadding} pb={defaultBottomPadding}>
                                    {this.renderHeading(faq.q)}
                                    {this.renderContent(faq.a)}
                                </Box>
                            )
                        )}
                    </DefaultWrapper>
                </Section>
            </Container>
            </>
        )
    }
}

export default Questions;
