import React from "react";
import { Text, Box, Link, Flex } from "rebass";

export const baseTheme = {
  breakpoints: ["40em", "64em"],
  fontSizes: {
    small: 13, 
    body: 14,
    medium: 16, 
    large: 20,
    xlarge: 32, 
  },
  colors: {
    text_dark: "#475009",
    text_light: "white",
    text_lighter: "rgba(255, 255, 255, 0.8)",
    text_sage: "#475009",
    sage: "#10422A",
    sage_light: "#878E59",
    peach: "#E8EBD0",
    seafoam: "#E8EBD0",
    chartreuse: "#FBFFD1",
  },
  space: {
    n_hugest: -128,
    n_huge: -64,
    n_small: -4,
    n_tiny: -2,
    tiny: 2, 
    smaller: 3,
    small: 4,
    smallMed: 6,
    medium: 8,
    large: 16, 
    xlarge: 32,
    huge: 64,
    hugest: 128,
  },
  fonts: {
    heading: "Recoleta-Black, system-ui, sans-serif",
    subheading: "PPEiko-LightItalic, system-ui, sans-serif",
    displayBody: "Recoleta-Regular, system-ui, sans-serif",
    subtext: "Recoleta-Thin, system-ui, sans-serif",    
    paragraph: "SpaceGrotesk-Light, system-ui, sans-serif",    
  },
};


export const text = {
  heading: {
    color: baseTheme.colors.text_light,
    fontFamily: baseTheme.fonts.heading,
    fontSize: [baseTheme.fontSizes.large, baseTheme.fontSizes.xlarge],
    lineHeight: 1.4,
  },
  subheading: {
    color: baseTheme.colors.text_dark,
    fontFamily: baseTheme.fonts.subheading,
    fontSize: [baseTheme.fontSizes.small, baseTheme.fontSizes.medium],
    lineHeight: 1.4,
  },
  displayBody: {
    color: baseTheme.colors.text_dark,
    fontFamily: baseTheme.fonts.displayBody,
    fontSize: [baseTheme.fontSizes.medium, baseTheme.fontSizes.large],
    lineHeight: 1.4,
    paddingBottom: baseTheme.space.large,
  },
  subtext: {
    color: baseTheme.colors.text_light,
    fontFamily: baseTheme.fonts.subtext,
    fontSize: [baseTheme.fontSizes.medium, baseTheme.fontSizes.large],
    lineHeight: 1.4,
  },
  paragraph: {
    color: baseTheme.colors.text_dark,
    fontFamily: baseTheme.fonts.paragraph,
    fontSize: [baseTheme.fontSizes.body, baseTheme.fontSizes.body],
    lineHeight: 1.4,
  },
};

export const Title = props => {
  return (
    <Text sx={text.heading} {...props} />
  );
};

export const Subheading = props => {
  return (
    <Text sx={text.subheading} marginBottom={baseTheme.space.tiny} {...props} />
  );
};

export const Subtext = props => {
  return (
    <Text sx={text.subtext} {...props} />
  );
};

export const DisplayText = ({ children, ...restProps }) => {
  return (
    <Text
      sx={text.displayBody}
      {...restProps}      
    >
      {children}
    </Text>
  );
};

export const Paragraph = ({ children, ...restProps }) => {
  return (
    <Text
      sx={text.paragraph}
      {...restProps}      
    >
      {children}
    </Text>
  );
};

export const Container = ({ children, ...restProps }) => {
  return (
    <Box 
      {...restProps}
    >
      {children}
    </Box>
  );
};

export const DefaultWrapper = ({ children, ...restProps }) => {
  let maxWidth = restProps.maxWidth || "960px";

  return (
    <Box 
      maxWidth={maxWidth}
      mx="auto"
      px={baseTheme.space.tiny}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export const DefaultResponsiveBox = ({ children, ...restProps }) => {
  let defaultGrid = [1, 1/2];
  let defaultSpacing = [baseTheme.space.tiny, 0];

  return (
    <Box 
      width={defaultGrid}
      py={defaultSpacing}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export const Section = ({ children, ...restProps }) => {
  let sectionPadding = [baseTheme.space.tiny, baseTheme.space.huge];
  
  return (
    <Box 
      boxSizing="content-box"
      p={sectionPadding}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export const StyledLink = ({ children, ...restProps }) => {
  return (
    <Link 
      fontFamily={baseTheme.fonts.paragraph}
      color={baseTheme.colors.text_sage}
      borderColor={baseTheme.colors.sage}
      sx={{
        ":hover": {
          fontWeight: "bold",
        },
        ":visited": {
          color: `${baseTheme.colors.sage_light}`
        }
      }}
      {...restProps}      
    >
      {children}
    </Link>
  );
};

export const HeaderLink = ({ children, ...restProps }) => {
  let borderColor = baseTheme.colors.text_lighter;

  return (
    <Link 
      fontFamily={baseTheme.fonts.displayBody}
      borderColor={baseTheme.colors.sage}
      sx={{
        "text-decoration": "none",
        ":hover": {
          borderBottom: `1px solid ${borderColor}`,
        }
      }}
      {...restProps}      
    >
      {children}
    </Link>
  );
};

export const BlockButton = ({ children, ...restProps }) => {
  let borderColor = baseTheme.colors.sage_light;
  let textColor = baseTheme.colors.text_sage;
  let letterSpacing = restProps.letterSpacing || "3px";

  return (
    <Link
      p={["24px", baseTheme.space.small]}
      fontFamily={baseTheme.fonts.subheading}
      color={textColor}
      opacity={0.8}
      sx={{
        border: `1px solid ${borderColor}`,
        borderRadius: "64px",
        boxShadow: `3px 3px 0px ${borderColor}`,
        textDecoration: "none",
        letterSpacing: letterSpacing,
        ":hover": {
          transform: "translateY(3px)",
          boxShadow: `0.5px 0.5px 0px ${borderColor}`,
          transition: "all 400ms", 
          cursor: "pointer"
        }
      }}
      {...restProps}
    >
        {children}
    </Link>
  );
};

export const WavySection = ({ children, ...restProps }) => {
  let backgroundWave = "url(/images/wave-2.svg)";
  let mainBgColor = baseTheme.colors.sage;

  return (
    <Section 
      textAlign="center"
      backgroundColor={mainBgColor} 
      mt={["-1px", baseTheme.space.n_huge]}
      mb={"-1px"}
      ml={"-1px"}
      style={{ 
        backgroundImage: backgroundWave,
        backgroundRepeat:"no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "contain",
      }}
      {...restProps} 
    >
      {children}
    </Section>
  );
};

export const HeaderNav = () => {

  const Pages = [
    {
      name: "Home",
      url: "/",
    }, 
    {
      name: "Travel",
      url: "/travel",
    },
    {
      name: "Registry",
      url: "/registry",
    },
    {
      name: "FAQ",
      url: "/faq",
    }
  ];

  return (
    <Flex 
      backgroundColor={baseTheme.colors.sage} 
      justifyContent="space-between" 
      px={[baseTheme.space.small, baseTheme.space.huge]} 
      pt={[baseTheme.space.small]} 
      pb={[baseTheme.space.tiny, baseTheme.space.small]} 
    >
      <DisplayText color={baseTheme.colors.text_light}>
        Elaine & Bryan
      </DisplayText>

      <Box>
        {Pages.map((page, index) => 
          (
            <HeaderLink 
              href={page.url} 
              color={baseTheme.colors.text_lighter}
              mx={baseTheme.space.tiny} 
              fontSize={baseTheme.fontSizes.small}>
                {page.name}
            </HeaderLink>
          )
        )}
      </Box>
    </Flex>
  );
}

