import React, { Component } from "react"
import { baseTheme, Container, Section, Title, WavySection,
  Paragraph, DefaultResponsiveBox, HeaderNav, DisplayText, StyledLink, DefaultWrapper } from "./components";
import { Flex, Box, Image} from "rebass"

var hotelRecs = [
    {
        name: "Hotel De Anza",
        loc: "San Jose",
        url: "https://www.hyatt.com/en-US/hotel/california/hotel-de-anza/sjcdh"
    },
    {
        name: "AC Hotel",
        loc: "San Jose",
        url: "https://www.marriott.com/en-us/hotels/sjcac-ac-hotel-san-jose-downtown/overview/"
    },
    {
        name: "The Inn at Saratoga",
        loc: "Saratoga",
        url: "https://www.innatsaratoga.com/", 
    }
];


class Travel extends Component {
    renderHeading(headingText) {
        return (
            <DisplayText>
                &#9758;  &nbsp;{headingText}
            </DisplayText>
        )
    }

    renderContent(content) {
        return (
            <Paragraph ml={[0, baseTheme.space.small]}>
                {content}
            </Paragraph>
        )
    }

    renderFlightInfo() {
        let title = "Flying In"
        let content = (
            <>
                We recommend flying into: 
                <ul>
                    <li>San Jose International Airport (~1 hr drive) </li>
                    <li>San Francisco International Airport (~1.5 hr drive) </li>
                </ul>
            </>
        );

        return (
            <Box>
                {this.renderHeading(title)}
                {this.renderContent(content)}
            </Box>
        )
    }

    renderTransportInfo() {
        let title = "Getting Around"
        let content = (
            <>
                We will have transportation from San Jose (TBD) to the venue and back on the day of the wedding. 
                There is limited parking and the roads are dark and windy at night, so we recommend taking the shuttle if you're unfamiliar with the area. 
            </>
        );

        return (
            <Box>
                {this.renderHeading(title)}
                {this.renderContent(content)}
            </Box>
        )
    }

    renderLodgingInfo() {
        let title = "Lodging"
        let content = (
            <>
                Our wedding weekend is the same as some universities' graduations. 
                Hotels and rentals will start to fill up fast, so we recommend booking soon! 
                <br /><br />
                Ben Lomond is fairly remote, so we suggest basing your stay near San Jose, 
                or nearby towns such as: Saratoga, Los Gatos, or Santa Clara. 
                <br /><br />
                Below are some recommendations: 
                <ul>
                    {hotelRecs.map((hotel, index) => 
                        (
                            <li>
                                <StyledLink href={hotel.url} target="_blank" rel="noopener noreferrer">
                                    {hotel.name}
                                </StyledLink>
                                &nbsp; ({hotel.loc})
                            </li>
                            
                        )
                    )}
                </ul>
            </>
        );

        return (
            <Box>
                {this.renderHeading(title)}
                {this.renderContent(content)}
            </Box>
        )
    }

    render() {
        let defaultBottomPadding = [baseTheme.space.small, baseTheme.space.small];
        let defaultTopPadding = [0, baseTheme.space.tiny];

        let offsetSizing = [baseTheme.space.n_tiny, baseTheme.space.n_huge];

        return (
            <>
            <HeaderNav />
            <Container>
                <WavySection height={["160px", "240px", "400px"]}>
                    <Title my={baseTheme.space.small} className="fadeInUpBig">
                        Travel Guide
                    </Title>
                </WavySection>                    
                <Section backgroundColor={baseTheme.colors.seafoam} mt={offsetSizing}>
                    <DefaultWrapper>
                        <Flex flexWrap='wrap'>
                            <DefaultResponsiveBox>
                                <Box pt={defaultTopPadding} pb={defaultBottomPadding}>
                                    {this.renderFlightInfo()}
                                </Box>
                                <Box pt={defaultTopPadding} pb={defaultBottomPadding}>
                                    {this.renderTransportInfo()}
                                </Box>
                                <Box pt={defaultTopPadding} pb={defaultBottomPadding}>
                                    {this.renderLodgingInfo()}
                                </Box>
                            </DefaultResponsiveBox>
                            <DefaultResponsiveBox>
                                <Image src="/images/map-space-grotesk.png" />
                            </DefaultResponsiveBox>
                        </Flex>
                    </DefaultWrapper>
                </Section>
            </Container>
            </>
        )
    }
}

export default Travel;
