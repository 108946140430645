import React, { Component } from "react"
import { baseTheme, Container, Section, Title, WavySection, BlockButton, Paragraph,
  Subheading, Subtext, DisplayText, DefaultResponsiveBox, HeaderNav, DefaultWrapper } from "./components";
import { Flex, Box, Image } from "rebass"

var welcomeDrinks = {
  name: "Welcome Drinks",
  date: "Friday, June 14",
  time: "6-10pm", 
  loc: "Barebottle Brewing",
  city: "Santa Clara, CA",
  attire: "casual attire",
  image: "/images/drinks.svg"
};

var mainEvent = {
  name: "Ceremony & Reception",
  date: "Saturday, June 15",
  time: "2:30-10pm", 
  loc: "Sequoia Retreat Center",
  city: "Ben Lomond, CA", 
  attire: "cocktail attire",
  image: "/images/eb-illo.png"
};

var rsvpLink = "https://withjoy.com/elainexbryan/rsvp"

class Home extends Component {

  renderSquiggleHeader(headerText) {
    let squigglePadding = [baseTheme.space.tiny, baseTheme.space.small];
    let squiggleWidth = ["80%", "100%"];

    let textColor = baseTheme.colors.text_sage;

    return (
      <Flex
        mb={baseTheme.space.small}
        alignItems="center"
        justifyContent="center"
      > 
        <Box mr={squigglePadding}>
          <Image 
            width={squiggleWidth}
            src="/images/small-squiggle-l.svg" />
        </Box>
        <Subheading color={textColor} minWidth={"100px"}>
          {headerText}
        </Subheading>
        <Box ml={squigglePadding}>
          <Image 
            width={squiggleWidth}
            src="/images/small-squiggle-r.svg" />
        </Box>
      </Flex>
    )
  }

  renderEventInfo(eventInfo) {
    let secondaryBgColor = baseTheme.colors.peach;
    let textColor = baseTheme.colors.text_sage;
    let headingOpacity = 0.8;
    
    return (
      <Section 
        textAlign="center" 
        pt={baseTheme.space.huge}
        pb={[baseTheme.space.small, baseTheme.space.hugest]}
        backgroundColor={secondaryBgColor}
      >
        {this.renderSquiggleHeader(eventInfo.name)}
        <DefaultWrapper>
          <Flex flexWrap='wrap'>
            <DefaultResponsiveBox>
              <Subheading color={textColor} opacity={headingOpacity}>when</Subheading>
              <DisplayText color={textColor}>
                {eventInfo.date} <br />
                {eventInfo.time} 
              </DisplayText>
            </DefaultResponsiveBox>
            <DefaultResponsiveBox>
              <Subheading color={textColor} opacity={headingOpacity}>where</Subheading>
              <DisplayText color={textColor}>
                {eventInfo.loc} <br />
                {eventInfo.city} 
              </DisplayText>
            </DefaultResponsiveBox>
          </Flex>
        </DefaultWrapper>
      </Section>
    )
  }

  renderEventAndIlloInfo(eventInfo, flexDirectionProp) {
    let secondaryBgColor = baseTheme.colors.peach;
    let textColor = baseTheme.colors.text_sage;
    let textOpacity = 0.8;
    let flexDirection = flexDirectionProp || "initial";

    return (
      <Section 
          textAlign="center" 
          pt={baseTheme.space.huge}
          pb={[baseTheme.space.small, baseTheme.space.hugest]}
          backgroundColor={secondaryBgColor}
        >
          {this.renderSquiggleHeader(eventInfo.date)}

        <DefaultWrapper>
          <Flex flexWrap="wrap" justifyContent={"center"} flexDirection={flexDirection}>
            <DefaultResponsiveBox>
              <Image maxHeight={["100px", "180px"]} src={eventInfo.image} py={baseTheme.space.tiny}/>
            </DefaultResponsiveBox>
            <DefaultResponsiveBox my={"auto"}>
              <Subheading color={textColor} fontSize={[baseTheme.fontSizes.large, baseTheme.fontSizes.large]}>
                {eventInfo.name} 
              </Subheading>
              <Paragraph color={textColor} opacity={textOpacity} 
                fontSize={[baseTheme.fontSizes.medium, baseTheme.fontSizes.medium]}
              >
                {eventInfo.loc} <br />
                {eventInfo.city} <br />
                {eventInfo.time} <br />
                {eventInfo.attire} 
              </Paragraph>
            </DefaultResponsiveBox>
          </Flex>
        </DefaultWrapper>
      </Section> 
    );
  }

  renderRSVPLink(){
    return(
      <Flex justifyContent={"center"} py={baseTheme.space.smallMed}>
        <BlockButton href={rsvpLink} target="_blank" rel="noopener noreferrer">
          R.S.V.P. &#9758;
        </BlockButton>
      </Flex>
    );
  }

  renderFooter() {
    return (
      <Box>
        <Section 
          textAlign="center"
          height={["auto"]}
          backgroundColor={baseTheme.colors.sage} 
          ml={"-1px"}
          style={{ 
            backgroundImage: "url(/images/wave-top.svg)",
            backgroundRepeat:"no-repeat",
            backgroundPosition: "top",
            backgroundSize: "contain",
          }}
        >
          <Box mt={[baseTheme.space.smallMed, baseTheme.space.hugest]}>
            <Image 
              maxHeight={["45px","100px"]}
              src="/images/birb.svg" 
              className="fadeInUp"
            />
          </Box>
        </Section>
        <HeaderNav />
      </Box>
    );
  }

  render() {
    let heroGrid = [1, 7/12];
    let contentGrid = [1, 5/12];

    return (
      <>
        <HeaderNav />
        <Container>
          <WavySection height={["80vh", "auto"]}>
            <Flex flexWrap='wrap'>
              <Box width={heroGrid}>
                <Box 
                  mx="auto" 
                  px={[baseTheme.space.smaller, 0]}
                >
                  <Image 
                    maxHeight={["85vh"]}
                    src="/images/pic-with-doodles.png" 
                    className="fadeInUp"
                  />
                </Box>
              </Box>
              <Box
                my="auto" 
                py={baseTheme.space.large}
                width={contentGrid}
                className="fadeInUpDos"
              >
                <Box mb={baseTheme.space.small}>
                  <Flex marginBottom={baseTheme.space.tiny} alignItems={"center"} justifyContent={"center"}>
                    <Title>Elaine</Title>
                    <Subtext px={baseTheme.space.tiny} opacity={0.7}>&</Subtext>
                    <Title>Bryan</Title>
                  </Flex>
                  <Subtext opacity={0.8}>
                    June 15, 2024 <br />
                    Ben Lomond, CA
                  </Subtext>

                  {/* render pointer  */}
                  <Title color={baseTheme.colors.text_lighter} 
                    p={baseTheme.space.small} opacity={0.5}
                    fontSize={[baseTheme.fontSizes.large, baseTheme.fontSizes.xlarge]}
                    className="bounce"
                  >
                    &#9759; 
                  </Title>
                </Box>
              </Box>
            </Flex>
          </WavySection>
          <Box height="10vh" backgroundColor={baseTheme.colors.seafoam} />
          <Box backgroundColor={baseTheme.colors.seafoam} >
            {this.renderEventAndIlloInfo(welcomeDrinks, ["initial", "row-reverse"])}
            {this.renderEventAndIlloInfo(mainEvent)}

            {this.renderRSVPLink()}
            {this.renderFooter()}
          </Box>
        </Container>
      </>
    )
  }
}

export default Home;
