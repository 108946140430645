import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

// Theme
import { ThemeUIProvider } from "theme-ui";
import theme from "./theme";

// Fonts
import "./fonts/Recoleta-Regular.ttf";
import "./fonts/Recoleta-Thin.ttf";
import "./fonts/Recoleta-Bold.ttf";
import "./fonts/Recoleta-Black.ttf";
import "./fonts/PPEiko-LightItalic.ttf";

ReactDOM.render(
  <ThemeUIProvider theme={theme}>
    <App />
  </ThemeUIProvider>,
  document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
