import React, { Component } from "react"
import { baseTheme, Container, Section, Title, WavySection, HeaderNav, DefaultWrapper, Paragraph, BlockButton} from "./components";
import { Link, Flex, Box } from "rebass"

var zolaRegistry = {
    name: "bryanandelaine",
    url: "https://www.zola.com/registry/bryanandelaine/"
};


class Registry extends Component {

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://widget.zola.com/js/widget.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        script.onload = () => {
          zolaRegistry = new Registry({
              container: '.zola-registry-embed', // querySelector to the element that will contain the widget
              config: {
                  apiKey: "zola-wjs"
              }
          });
        }
    }

    renderZolaWidget() {
        return (
            <Flex py={[baseTheme.space.small, baseTheme.space.smallMed]}>
                <Link className="zola-registry-embed" href={zolaRegistry.url} data-registry-key={zolaRegistry.name}></Link>
            </Flex>
        );
    }

    renderZolaLink() {
        return (
            <Flex justifyContent={"center"} py={baseTheme.space.small}>
                <BlockButton href={zolaRegistry.url} target="_blank" rel="noopener noreferrer"
                    letterSpacing={"0px"}>
                    View our registry
                </BlockButton>
            </Flex>
        );
    }

    render() {
        let offsetSizing = [baseTheme.space.n_tiny, baseTheme.space.n_huge];

        return (
            <Box minHeight="100vh" backgroundColor={baseTheme.colors.seafoam}>
                <HeaderNav />
                <Container>
                    <WavySection height={["160px", "240px", "400px"]}>
                        <Title my={baseTheme.space.small} className="fadeInUpBig">
                            Registry
                        </Title>
                    </WavySection>                    
                    <Section backgroundColor={baseTheme.colors.seafoam} mt={offsetSizing}>
                        <DefaultWrapper>
                            <DefaultWrapper maxWidth={"500px"} 
                                mb={[baseTheme.space.small, baseTheme.space.smallMed]}
                            >
                                <Paragraph>
                                    Your presence at our wedding is the greatest present we could ask for! We only ask that you have a great time. <br /><br />
                                    If you'd wish to help us spruce up our new home or contribute towards our honeymoon fund, we've put together our wishlist in the following registry.
                                </Paragraph>
                            </DefaultWrapper>
                           {this.renderZolaLink()}
                        </DefaultWrapper>
                    </Section>
                </Container>
            </Box>
        )
    }
}

export default Registry;
